<template>
    <div class="mb-4">
        <div class="text-gray-400 title text-left duration-500 pc_show">我的服务</div>
        <div class="sm:mt-3">
            <div class="font-bold text-left">绑定设备</div>
            <div class="table w-full">
                <div class="table_title duration-500 flex items-center w-full">
                    <div
                        v-for="(item,index) in tableTitle"
                        :key="index"
                        class="h-full flex items-center duration-500"
                        style="width:33.33%"
                        :class="index == 0 ? 'justify-start' : index == 4 ? 'justify-end' : 'justify-center'"
                    >{{ item }}</div>
                </div>
                <ul class="w-full" v-if="getEquipment.length > 0">
                    <li
                        class="flex w-full duration-500 items-center"
                        v-for="(item,index) in getEquipment"
                        :key="index"
                    >
                        <div
                            class="flex justify-start items-center li_item duration-500"
                        >{{ item.commodityName }}</div>
                        <div
                            class="flex justify-center items-center li_item duration-500"
                        >{{ item.factory }}</div>
                        <div
                            class="flex justify-center items-center li_item duration-500"
                        >{{ item.warrantyTime }}年</div>
                        <div
                            class="flex justify-center items-center li_item duration-500"
                        >{{ calculateDate(item.warrantyTime, item.warrantyDate) }}年</div>
                        <div
                            class="flex justify-end items-center li_item duration-500 text-primary cursor-pointer"
                        >
                            <span @click="goPage('/index/warranty/apply')">保修</span>/
                            <span @click="goPage('/index/sales/equipment?tab=2')">维修</span>
                        </div>
                    </li>
                </ul>
                <div
                    v-else
                    class="flex justify-center items-center text-gray-500 null_box py-3"
                >未绑定相关设备</div>
            </div>
        </div>
        <div class="log sm:mt-4">
            <div class="font-bold text-left sm:mb-2 cate_title">保修/维修记录</div>
            <ul v-if="maintainList.leng > 0">
                <li
                    v-for="item in maintainList"
                    :key="item.maintainId"
                    class="mb-2 flex items-center justify-start"
                >
                    <div class="list_dscs"></div>
                    <div>{{ item.date }} 电脑屏幕进行维修</div>
                </li>
            </ul>
            <div class="null text-gray-500">暂无维修记录</div>
        </div>
        <div class="feedback sm:mt-4">
            <div class="font-bold text-left cate_title sm:mb-2">投诉建议反馈信息</div>
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="尽量把问题描述清楚，字数不要超过300字"
                v-model="feedbackContext"
                maxlength="10"
            ></el-input>
            <el-button type="primary" class="mt-2 w-1/3" @click="addFeedback">提交</el-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            tableTitle: ['设备名称', '设备出厂', '保修时间', '剩余时间', '操作'],
            tabIndex: 0,
            feedbackContext: "",
            maintainList: []
        };
    },
    computed: {
        ...mapGetters(['getEquipment'])
    },
    created() {
        this.$store.dispatch("aquireEquipment", this.$EventBus)
        this.findRepairList()
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        // 计算剩余保修时间
        calculateDate(time, date) {
            if (date) {
                let start = new Date(date)
                let current = new Date()
                let distance = current.getFullYear() - start.getFullYear()
                return time - distance
            } else {
                return "--"
            }
        },
        goPage(path) {
            this.$router.push({ path })
        },
        // 获取设备维修信息
        findRepairList() {
            this.$request({
                method: "get",
                url: this.$requestPath.findRepairList,
                data: {}
            }).then(res => {

                if (res.code == 0) {
                    console.log("设备维修列表", res)
                    res.data.forEach(item => {
                        let arr = item.maintainTime.split(" ")
                        item.date = arr[0]
                    })
                    this.maintainList = []
                    this.maintainList.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取设备维修列表报错", error)
            })
        },
        // 提交建议和反馈
        addFeedback() {
            if (this.isNull(this.feedbackContext)) {
                this.$EventBus.$emit("toast", { type: 'error', message: "请输入反馈信息" })
                return
            }
            this.$request({
                method: "post",
                url: this.$requestPath.addFeedback,
                data: {
                    feedbackContext: this.feedbackContext
                }
            }).then(res => {
                console.log("提交意见反馈", res)
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: "反馈信息提交成功" })
                    this.feedbackContext = ''
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("提交意见反馈失败", error)
            })
        }
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}
.table_title {
    background: #e8e8e8;
}
.li_item {
    width: 20%;
    padding: 0 0.1875rem;
    word-wrap: break-word;
}
.list_dscs {
    height: 0.375rem;
    width: 0.375rem;
    background: black;
    border-radius: 50%;
    margin-right: 0.375rem;
}

@media screen and (max-width: 640px) {
    .log,
    .feedback {
        margin-top: 0.625rem;
    }
    .cate_title {
        margin-bottom: 0.3125rem;
    }
    .table {
        margin-top: 0.625rem;
        .table_title {
            // height: 2.1875rem;
            font-size: 0.8125rem;
            padding: 0.5rem 0.625rem;
        }
        ul {
            li {
                padding: 0.375rem 0.625rem;
                font-size: 0.75rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .pc_show {
        display: none;
    }
    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .table {
        margin-top: 0.625rem;
        .table_title {
            padding: 0.625rem 0.625rem;
        }
        ul {
            li {
                padding: 1.25rem 0.625rem;
                font-size: 0.875rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
@media screen and (min-width: 900px) {
    .pc_show {
        display: block;
    }
    .title {
        font-size: 1.875rem;
    }
}
</style>